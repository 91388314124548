import React, { Component } from 'react';
import {Link} from 'gatsby';
// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudybrhs.scss';

import imgQuote from "../images/quotation_mark.webp";

// import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFullTwo extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: this.props.backgroundColor
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12 bgImg" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-brhs">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <small>Showcasing History &amp; Embracing a New Era of Growth</small>
                        <img src={imgQuote} alt=""/>
                        <h1>{pageContext.acf.header_text}</h1>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width={pageContext.acf.client_logo.width} height={pageContext.acf.client_logo.height} alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph">
                            <p>While many non-profit organizations understandably paused strategic initiatives throughout the pandemic, the Boca Raton Historical Society has and continues to forge ahead. Established in 1972, the mission and purpose of the organization is to collect, preserve, and present information and artifacts relevant to the past and evolving history of Boca Raton, and to maintain a visible role in the education and the advocacy of historic preservation in the burgeoning South Florida community.</p>
                            <p>With a multi-million-dollar renovation of its museum scheduled for late 2021 re-opening, as well as the golden anniversary of the organization coming in 2022, the timing was perfect to look inward and revitalize the brand and visual identity of one of the region's most important community and cultural organizations… and Merit Mile was ready for the challenge.</p>
                        </div>
                        <a href="https://www.meritmile.com/uploads/2021/12/boca-raton-historical-society-merit-mile-case-study.pdf" className="btn sitebtn" target="_blank" rel="noopener noreferrer">Read Case Study as PDF</a>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12 col-md-7">
                        <div className="first-paragraph">
                            <h3>Challenges &amp; Opportunities</h3>
                            <p>The consequences of the COVID-19 pandemic severely impacted most organizations, and the Boca Raton Historical Society was no exception. However, the cancellation of enormously important annual fund-raising events inspired leadership to reevaluate many financial, operational, and strategic priorities, including the organization's brand identity, website, press relations, and marketing communications. </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 text-center">
                        <img src="https://www.meritmile.com/uploads/2021/12/brhs-logo-new.jpg" className="first-paragraph-image img-maxLogo" width="380" height="297" alt="Boca Raton Historical Society" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph">
                            <p>With the help of Merit Mile CEO and Boca Raton Historical Society Trustee, Mark Reino, the vision and planning for this strategic pursuit was prioritized, and key stakeholders were at the ready to help accomplish a number of objectives, including:</p>
                            <ol>
                                <li>Visually rebrand the Boca Raton Historical Society</li>
                                <li>Integrate a complementary brand identity for a benefactor-sponsored Museum</li>
                                <li>Produce a suite of marketing assets for communications, fund-raising, and events</li>
                                <li>Evaluate website requirements and steer vendor selection processes</li>
                                <li>Promote the organization among targeted editorial media outlets</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="second-paragraph">
                            <h3>Solutions</h3>
                            <p>Rebranding, in general, is always a tricky topic for anyone associated with the brand in question. Recognizing this, Merit Mile assembled a small yet influential team of Historical Society brand ambassadors that would collectively steer decision making for the entire organization. From committee chairs, to gift shop operators, to executive leadership; a team of 6 was assembled for ongoing reviews and feedback.</p>
                            <p>From here, Merit Mile engaged in many conversations with stakeholders and influencers. Conversations like these are essential to the rebranding process as this type of discovery helps identify needs while also evolving into important consensus-building positions for the visual presentations and supporting rationale ahead.</p>
                            <p>The final two steps, ahead of any visual designs, were vitally important. And believe it or not, these steps made the designing process more efficient rather than belaboring it.</p>
                            <p>First, Merit Mile performed a competitive audit. The audit included a visual and strategic review of nationwide historical societies, assessing their strengths and weaknesses, while highlighting areas for unique differentiation for the Boca Raton Historical Society.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="third-paragraph">
                            <h3>Merit Mile Brand Transformation Process</h3>
                            <img src="https://www.meritmile.com/uploads/2021/12/mm-process.jpg" className="img-fluid" alt="Merit Mile Brand Tranformation Process" style={{ marginTop: '2em', marginBottom: '2em'}} />
                            <p>Then, with an analysis of existing marketplace branding in hand, Merit Mile reviewed the inventory of existing Boca Raton Historical Society brand communications. Like many organizations or brands that have been in existence for a decade or more, the old brand assets were visually diverse and lacked continuity.</p>
                            <p>This two-step process, ahead of any designing, afforded the artists and branding experts at Merit Mile a complete view of the road ahead, both visually and strategically. As a result of this audit and review, the marketing and branding gaps became obvious, and the development of the new identity could now begin and conclude in weeks instead of months.</p>
                        </div>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-6">
                        <div className="two-col">
                            <p>In effect, the Merit Mile process of planning, strategy alignment, and consensus-building served as the blueprint for what would become a very efficient branding and design exploration.</p>
                            <p>In this exploration, it was determined that stakeholders had different ideas and a variety of opinions and preferences. Altogether, there was no single, correct solution. However, when presented and rationalized in an organized grid format, the feedback gradually began to unify.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="two-col">
                            <h4>Brand Positioning Grid</h4>
                            <hr />
                            <img src="https://www.meritmile.com/uploads/2021/12/brhs-brand-grid.jpg" alt="Brand Positioning Grid" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-6">
                        <div className="two-col">
                            <h4>Brand Tone &amp; Personality</h4>
                            <hr />
                            <img src="https://www.meritmile.com/uploads/2021/12/brhs-brand-tone.jpg" alt="Brand Positioning Grid" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="two-col">
                            <p>Continuing the dialog with strategic “if/then” rationale fostered more consensus visually. The approach of planning, alignment, and consensus-building—again—enabled the final decision-making to become much less fragmented and much more reassuring. And once the design exploration process evolved to a categorical representation of the new branding options, the final decision became clear.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph">
                            <h3>Results</h3>
                        </div>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-12 col-md-6">
                        <div className="two-col">
                            <p>A plan is only as good as the execution.</p>
                            <p>With the final brandmark selected, the Merit Mile creative studio got into high gear and began the process of producing the balance of marketing materials the organization required.</p>
                            <p className="text-center">
                                <img src="https://www.meritmile.com/uploads/2021/12/brhs-creative-samples-01.jpg" alt="BRHS Creative 1" className="img-fluid" />
                            </p>
                            <p className="text-center">
                                <img src="https://www.meritmile.com/uploads/2021/12/brhs-creative-samples-03.jpg" alt="BRHS Creative 3" className="img-fluid" />
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="two-col">
                                    <h4>Original Branding</h4>
                                    <hr />
                                    <img src="https://www.meritmile.com/uploads/2021/12/brhs-logo-original.jpg" alt="Brand Positioning Grid" className="img-fluid" style={{ display: 'block', width: '105px', marginLeft: 'auto', marginRight: 'auto'}} />
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="two-col">
                                    <h4>New Branding</h4>
                                    <hr />
                                    <img src="https://www.meritmile.com/uploads/2021/12/brhs-logo-new.jpg" alt="Brand Positioning Grid" className="img-fluid" style={{ display: 'block', width: '210px', marginLeft: 'auto', marginRight: 'auto'}} />
                                </div>
                            </div>
                        </div>
                        <div className="two-col">
                            <p>With a new benefactor-named museum component, it was only a matter of time before the Historical Society as well as The Schmidt Boca Raton History Museum were conjoined in perfect brand harmony. Similarly, the Society’s social media platforms were restyled and unified, as were all advertising and communications templates. </p>
                            <p>Deliverables:</p>
                            <ul>
                                <li>Visual Branding &amp; Identity Exploration</li>
                                <li>Marketing Audit &amp; Competitive Research</li>
                                <li>Brand Guidelines</li>
                                <li>Press Release &amp; Editorial Pitching</li>
                                <li>Print &amp; Digital Advertising Development</li>
                                <li>Website Consulting</li>
                                <li>Social Media Platforms Refresh</li>
                                <li>Branded Asset Suites, Physical &amp;Digital</li>
                            </ul>
                            <p className="text-center">
                                <img src="https://www.meritmile.com/uploads/2021/12/brhs-creativesamples-02.jpg" alt="BRHS Creative 2" className="img-fluid" />
                            </p>
                            <p className="text-center">
                                <img src="https://www.meritmile.com/uploads/2021/12/brhs-creative-samples-04.jpg" alt="BRHS Creative 4" className="img-fluid" />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="first-paragraph">
                            <p>Ultimately, the strategic marketing message behind this body of work for the Boca Raton Historical Society can be distilled into one formula for success: proper planning yields alignment and consensus, which then enables informed, assured, and efficient decision-making.</p>
                            <p className="text-center"><a href="https://www.meritmile.com/uploads/2021/12/boca-raton-historical-society-merit-mile-case-study.pdf" target="_blank" className="btn sitebtn">Read Case Study as PDF</a></p>
                            <p className="bold">To view more creative samples for the Boca Raton Historical Society, please visit <Link to="/portfolio/"><u style={{ color: '#003595'}}>www.MeritMile.com/Portfolio/</u></Link>. And for more information or to arrange a conversation with a Merit Mile branding specialist, please email us at <a href="mailto:mailto:Hello@MeritMile.com?subject=BRHS%20Case%20Study%20Inquiry"><u style={{ color: '#003595'}}>Hello@MeritMile.com</u></a> or call us at 1-561-362-8888 today!</p>
                            <p><br /></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fullWidthImageWrapper">
                <img
                    src="https://www.meritmile.com/uploads/2021/09/mm-divider.jpg"
                    alt="Merit Mile Icon Divider"
                    className="img-fluid"
                />
            </div>
            <div className="about-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>About Merit Mile</h2>
                        </div>
                    </div>
                    <div className="row margin-b">
                        <div className="col-md-6">
                            <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services provider focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines. To learn more, please email us at Hello@MeritMile.com, call us at 1-561-362-888, or visit us at www.MeritMile.com. </p>
                        </div>
                        <div className="col-md-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Headquarter in Boca Raton, FL" /></div>
                    </div>
                </div>
            </div>
            <div className="container quote-wrapper">
                <div className="row">
                    <div className="col-12">
                        <p>
                            For more insights and to connect<br />
                            with Merit Mile <a href="mailto:mailto:Hello@MeritMile.com?subject=BRHS%20Case%20Study%20Inquiry"><b style={{ color: "black" }}>email us here</b>
                            </a>
                        </p>
                    </div>
                </div>
            </div>

        </div>

    </Layout>
)